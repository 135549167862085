html {
  --red: #b00;
  --red-dark:#960000;
  --red-hover: rgba(150, 0, 0, .08);
  --gray: #666666;
  --dark-gray: #333;
  --pale-blue: #81b4f5;
  --chatbot-color: #fefefe;
  --background-color: #f2f2f2;
  --header-color: white;
  --action-color: var(--red);
  --border-color: rgb(233, 229, 228);
  --placeholder-color: #666666;
  font-family: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  --img-border: 0.2px solid rgba(128, 128, 128, 0.3);
  --header-height: 5rem;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  font-weight: 400;
  color: black;
  caret-color: black;
  line-height: normal;
}
/* Accessibility threshold for actionable touch size */
button {
  min-width: 44px;
  min-height: 44px;
}
.darkMode {
  --header-color: #2f2f2f;
  --background-color: #121212;
  --chatbot-color: #414141;
  --placeholder-color: #D4D4D4;
  --gray: #D4D4D4;
  color: white;
  caret-color: white;
}
a, button {
  font-weight: 600;
}
a {
  color: var(--red);
  text-decoration: none;
}
a:hover {
  color: var(--red-dark);
  text-decoration: underline;
}
.darkMode a {
  color: var(--pale-blue);
}
.darkMode a:hover {
  color: #4d9afc;
  text-decoration: underline;
}
footer, header {
  background-color: var(--header-color);
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
html, body {
  height: 100%;
  width: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  align-items: center;
  height: 100%;
  position: fixed;
  width: 100%;
}
::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--placeholder-color);
}

::-ms-input-placeholder {
  color: var(--placeholder-color);
}
hr {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: var(--border-color);
}
hr.vertical {
    align-self: stretch;
    height: auto;
    width: 1px;
    margin: 0 .5rem;
}
.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-wrap {
  flex-wrap: wrap !important
}

.align-items-end {
  align-items: flex-end !important
}

.align-items-center {
  align-items: center !important
}

.justify-content-space-between {
  justify-content: space-between !important
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.button {
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  transition: background 250ms ease-in-out, 
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.osu-button {
  text-decoration: none;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1px solid var(--action-color);
  background-color: var(--chatbot-color);
  color: var(--action-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 1.5rem;
}
.osu-button:hover {
  background-color: var(--action-color);
  border: 2px solid var(--action-color);
  color: var(--chatbot-color);
}
.darkMode .osu-button {
  border: 2px solid white;
  background-color: var(--chatbot-color);
  color: white;
}
.osu-button:hover {
  background-color: var(--action-color);
  border: 2px solid white;
  color: white;
}
.primary-container {
  background-color: var(--chatbot-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
#osu-navbar {
  width: 100%;
  max-width: 100%;
  padding: 0;
}